import { Button, Divider, Grid2, MenuItem, Paper } from "@mui/material";
import { HealthRecord, HealthRecordGenderEnum } from "@syadem/kairos-citizen-js";
import { Formik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { Country } from "../../../domain/country";
import { formatDateForApi } from "../../../utils/date";
import { dayjs, localizedPlaceholder } from "../../../utils/dayjs";
import { TranslateFunction, createTranslateFn } from "../../../utils/formUtils";
import { compareNormalizedString } from "../../../utils/string";
import yup from "../../../utils/yup";
import { CitiesAutocomplete } from "../../components/CitiesAutocomplete";
import { StyledAutocomplete } from "../../components/mui/StyledAutoComplete";
import { CancelButton } from "../../components/mui/StyledButtons";
import StyledDatepicker from "../../components/mui/StyledDatepicker";
import { StyledInput } from "../../components/mui/StyledInput";
import { StyledSelect } from "../../components/mui/StyledSelect";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { CapitalizeFirstNames } from "../../../utils/name";
import { useCountryConfig } from "../../hooks/useCountryConfig";
import { AnyObject, OptionalObjectSchema, TypeOfShape } from "yup/lib/object";
import { RequiredStringSchema } from "yup/lib/string";
import { RequiredDateSchema } from "yup/lib/date";

export type HealthRecordFormHandler = (
  body: {
    healthRecord: {
      firstNames: string;
      lastName: string;
      customaryName: string | null;
      birthDate: Date;
      gender: HealthRecordGenderEnum;
      externalId: string | null;
    };
  },
  id?: string,
) => void;

export interface HealthRecordFormProps {
  onSubmit?: HealthRecordFormHandler;
  healthRecord?: HealthRecord;
  isReadonly?: boolean;
}

type HealthRecordSchema = {
  externalId: yup.StringSchema<string | undefined, AnyObject, string | undefined>;
  firstNames: RequiredStringSchema<string | undefined, AnyObject>;
  lastName: RequiredStringSchema<string | undefined, AnyObject>;
  customaryName: yup.StringSchema<string | undefined, AnyObject, string | undefined>;
  birthDate: RequiredDateSchema<Date | undefined, AnyObject>;
  gender: RequiredStringSchema<string | undefined, AnyObject>;
  zipCode: RequiredStringSchema<string | undefined, AnyObject>;
  birthCountryCode: yup.StringSchema<string | undefined, AnyObject, string | undefined>;
  birthCityCode: yup.StringSchema<string | undefined, AnyObject, string | undefined>;
};

export type YupHealthRecordSchema = OptionalObjectSchema<
  HealthRecordSchema,
  AnyObject,
  TypeOfShape<HealthRecordSchema>
>;

export function HealthRecordForm({ onSubmit, healthRecord, isReadonly }: HealthRecordFormProps) {
  const { t, getObject, locale } = useI18n();
  const translateErrors: TranslateFunction = useMemo(() => createTranslateFn(t), [t]);
  const { defaultCountryCode, zipCode, securityNumber } = useCountryConfig();
  const [datePickerOpened, setDatePickerOpened] = useState<boolean>(false);
  const handleDatePickerOpened = useCallback(() => {
    setDatePickerOpened(true);
  }, []);

  const [birthCountryCodeInput, setBirthCountryCodeInput] = useState<string | undefined>(undefined);
  const countries = useMemo(() => {
    return Object.entries(getObject("countries")).reduce(
      (acc: Country[], [key, value]) => [...acc, { code: key, name: value }],
      [],
    );
  }, [getObject]);

  const healthRecordSchema: YupHealthRecordSchema = yup.object({
    externalId: yup.string().test(securityNumber.validator),
    firstNames: yup.string().max(50).required(),
    lastName: yup.string().max(50).required(),
    customaryName: yup.string().max(50),
    birthDate: yup
      .date()
      .min(dayjs.utc("1900-01-01").startOf("day").toDate())
      .max(dayjs.utc().endOf("day").toDate())
      .required(),
    gender: yup.string().required(),
    zipCode: yup.string().required().min(zipCode.length).max(zipCode.length).matches(zipCode.regex),
    birthCountryCode: yup.string(),
    birthCityCode: yup.string(),
  });

  return (
    <Paper
      sx={{
        overflow: "hidden",
        backgroundColor: "background.paper",
        border: `solid 1px ${theme.palette.neutral[200]}`,
        maxWidth: "900px",
      }}
      elevation={0}
    >
      <Formik
        initialValues={{
          externalId: healthRecord?.externalId || "",
          firstNames: healthRecord?.firstNames || "",
          lastName: healthRecord?.lastName || "",
          customaryName: healthRecord?.customaryName || "",
          birthDate: healthRecord?.birthDate ? dayjs.utc(healthRecord?.birthDate).toDate() : "",
          gender: healthRecord?.gender || "",
          zipCode: healthRecord?.zipCode || "",
          birthCountryCode: healthRecord?.birthCountryCode || defaultCountryCode,
          birthCityCode: (healthRecord?.birthCityCode as string) || "",
        }}
        validationSchema={healthRecordSchema}
        onSubmit={(values) => {
          const payload = {
            healthRecord: {
              externalId: values.externalId === "" ? null : values.externalId,
              firstNames: values.firstNames.trim(),
              lastName: values.lastName.trim(),
              customaryName: values.customaryName.trim() === "" ? null : values.customaryName.trim(),
              birthDate: formatDateForApi(values.birthDate as Date),
              gender: values.gender as HealthRecordGenderEnum,
              zipCode: values.zipCode.trim(),
              birthCountryCode: values.birthCountryCode.trim() === "" ? null : values.birthCountryCode.trim(),
              birthCityCode: values.birthCityCode.trim() === "" ? null : values.birthCityCode.trim(),
            },
          };

          if (healthRecord) {
            if (onSubmit) onSubmit(payload, healthRecord.id);
          } else {
            if (onSubmit) onSubmit(payload);
          }
        }}
      >
        {({ errors, getFieldProps, touched, handleSubmit, setFieldValue, setFieldTouched, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid2 container rowSpacing={2} columnSpacing={4} justifyContent="space-between" padding={4}>
              <Grid2 size={12} sx={{ mb: 1 }}>
                <securityNumber.Input
                  isReadonly={isReadonly}
                  translateErrors={translateErrors}
                  openDatepicker={handleDatePickerOpened}
                />
              </Grid2>
              <Grid2 size={12} sx={{ mb: 1 }}>
                <Divider variant="middle" sx={{ opacity: "0.6" }} />
              </Grid2>
              <Grid2 size={{ xs: 12, lg: 6 }}>
                <StyledInput
                  required
                  fullWidth
                  size="small"
                  error={touched.firstNames && !!errors.firstNames}
                  errorMessage={errors.firstNames}
                  touched={touched.firstNames}
                  translateErrors={translateErrors}
                  label={t("common.user_infos.first_names")}
                  placeholder="ex : Jean"
                  readOnly={isReadonly}
                  testId="firstNames"
                  {...getFieldProps("firstNames")}
                  onChange={(e) => {
                    const formattedValue = CapitalizeFirstNames(e.target.value);
                    setFieldValue("firstNames", formattedValue);
                  }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, lg: 6 }}>
                <StyledInput
                  required
                  fullWidth
                  size="small"
                  error={touched.lastName && !!errors.lastName}
                  errorMessage={errors.lastName}
                  touched={touched.lastName}
                  translateErrors={translateErrors}
                  label={t("common.user_infos.last_name")}
                  placeholder="ex : Dupont"
                  readOnly={isReadonly}
                  testId="lastName"
                  {...getFieldProps("lastName")}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, lg: 6 }}>
                <StyledInput
                  id="customaryName"
                  fullWidth
                  size="small"
                  label={t("common.user_infos.customary_name")}
                  placeholder="ex : Dubois"
                  readOnly={isReadonly}
                  testId="customaryName"
                  {...getFieldProps("customaryName")}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, lg: 6 }}>
                <StyledDatepicker
                  placeholder={localizedPlaceholder(locale, t)}
                  label={t("common.user_infos.birth_date")}
                  testId="birthDate"
                  required
                  error={touched.birthDate && !!errors.birthDate}
                  errorMessage={errors.birthDate}
                  touched={!!touched.birthDate}
                  translateErrors={translateErrors}
                  fullWidth
                  maxDate={dayjs()}
                  readOnly={isReadonly}
                  {...getFieldProps("birthDate")}
                  onChange={(date) => {
                    setFieldTouched("birthDate", true);
                    setFieldValue("birthDate", date ? date.toDate() : "");
                  }}
                  open={datePickerOpened}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, lg: 6 }}>
                <StyledSelect<HealthRecordGenderEnum>
                  label={t("common.gender.s")}
                  fullWidth
                  placeholder={`ex : ${t("common.gender.m")}`}
                  error={touched.gender && !!errors.gender}
                  errorMessage={errors.gender}
                  touched={touched.gender}
                  translateErrors={translateErrors}
                  required
                  testId="gender"
                  renderValue={(gender) => t(`common.gender.${gender.toLowerCase() as "m" | "f"}`)}
                  readOnly={isReadonly}
                  onOpen={() => setFieldTouched("gender", true)}
                  {...getFieldProps("gender")}
                >
                  <MenuItem value="M" data-testid="genderM">
                    {t("common.gender.m")}
                  </MenuItem>
                  <MenuItem value="F" data-testid="genderM">
                    {t("common.gender.f")}
                  </MenuItem>
                </StyledSelect>
              </Grid2>
              <Grid2 size={{ xs: 12, lg: 6 }}>
                <StyledInput
                  id="zipCode"
                  fullWidth
                  size="small"
                  error={touched.zipCode && !!errors.zipCode}
                  errorMessage={errors.zipCode}
                  touched={touched.zipCode}
                  translateErrors={translateErrors}
                  label={t("common.user_infos.zip_code")}
                  required
                  placeholder={zipCode.placeholder}
                  readOnly={isReadonly}
                  testId="zipCode"
                  {...getFieldProps("zipCode")}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, lg: 6 }}>
                <StyledAutocomplete
                  id="birthCountryCode"
                  label={t("common.user_infos.birth_country")}
                  error={touched.birthCountryCode && !!errors.birthCountryCode}
                  errorMessage={errors.birthCountryCode}
                  touched={touched.birthCountryCode}
                  translateErrors={translateErrors}
                  fullWidth
                  placeholder="ex : France"
                  readOnly={isReadonly}
                  options={countries}
                  filterOptions={(x) => x.filter((e) => compareNormalizedString(e.name, birthCountryCodeInput || ""))}
                  getOptionLabel={(country: Country) => country.name || ""}
                  value={
                    countries.find(
                      (country) => country.code === (values.birthCountryCode || healthRecord?.birthCountryCode),
                    ) || null
                  }
                  onInputChange={(_event, newCountryCode, reason) => {
                    if (reason === "input") {
                      setBirthCountryCodeInput(newCountryCode);
                    }
                  }}
                  onChange={(_event, newCountryCode) => {
                    setFieldTouched("birthCountryCode", true);
                    setFieldValue("birthCountryCode", newCountryCode?.code);

                    if (newCountryCode?.code !== "FRA") {
                      setFieldValue("birthCityCode", "");
                    }

                    if (!newCountryCode) {
                      setBirthCountryCodeInput(undefined);
                      setFieldValue("birthCountryCode", "");
                    }
                  }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, lg: 6 }}>
                {values.birthCountryCode === "FRA" && (
                  <CitiesAutocomplete
                    label={t("common.user_infos.birth_place")}
                    error={touched.birthCityCode && !!errors.birthCityCode}
                    errorMessage={errors.birthCityCode}
                    touched={touched.birthCityCode}
                    translateErrors={translateErrors}
                    placeholder={t("common.user_infos.birth_place")}
                    readOnly={isReadonly}
                    cityCode={values.birthCityCode}
                    onChange={(city) => {
                      setFieldTouched("birthCityCode", true);
                      if (!city) {
                        setBirthCountryCodeInput(undefined);
                        setFieldValue("birthCityCode", "");
                      } else {
                        setFieldValue("birthCityCode", city.code);
                      }
                    }}
                  />
                )}
              </Grid2>

              <Grid2 container justifyContent="space-between" size={12} sx={{ marginTop: "30px" }}>
                <div>
                  <CancelButton href="/health-records" variant="contained">
                    {t("common.cta.cancel")}
                  </CancelButton>
                </div>
                {!isReadonly && (
                  <Button variant="contained" type="submit" disableElevation data-testid="validate">
                    {t("common.cta.validate")}
                  </Button>
                )}
              </Grid2>
            </Grid2>
          </form>
        )}
      </Formik>
    </Paper>
  );
}
